import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  TERMS_AND_CONDITIONS,
  PRIVACY_POLICY,
  COMMUNITY_GUIDELINES,
  SAFETY_TIPS,
} from '../../data/terms';
import Header from '../../components/HeaderSection/Header';
import FooterSection from '../../components/FooterSection/Footer';
import './TermPage.css';

const TermPage = () => {
  const location = useLocation();
  console.log(location.pathname);
  const headerData = useSelector((state) => state.schema.schema.header);
  const footerData = useSelector((state) => state.schema.schema.footer);

  let data;

  switch (location?.pathname) {
    case '/terms-and-conditions':
      data = TERMS_AND_CONDITIONS;
      break;
    case '/privacy-policy':
      data = PRIVACY_POLICY;
      break;
    case '/community-guidelines':
      data = COMMUNITY_GUIDELINES;
      break;
    case '/safety-tips':
      data = SAFETY_TIPS;
      break;
    default:
      data = SAFETY_TIPS;
      break;
  }

  return (
    <div className='container'>
      <Header data={headerData} />
      <div className='term_container'>
        <div className='term_title_container'>
          <p className='term_title'>{data.headline}</p>
          {data.subtitle ? (
            <p className='term_subtitle'>{data.subtitle}</p>
          ) : null}
        </div>
        <div className='term_text_container'>
          {data.textContainer.map((section, index) => (
            <div className='section_text' key={section.id}>
              {section.subheadline ? (
                <p className='section_subheadline'>{section.subheadline}</p>
              ) : null}
              {section.text.map((textObj, index) => (
                <>
                  <p className='term_paragraph' key={textObj.id}>
                    {textObj.text}
                  </p>
                  {textObj.textContainer &&
                    textObj.textContainer.map((textObj) => (
                      <li className='term_point' key={textObj.id}>
                        {textObj.text}
                      </li>
                    ))}
                </>
              ))}
            </div>
          ))}
        </div>
      </div>
      <FooterSection data={footerData} />
    </div>
  );
};

export default TermPage;
