import React from 'react';
import { useSelector } from 'react-redux';

import Header from '../../components/HeaderSection/Header';
import Hero from '../../components/HeroSection/Hero';
import CardsSection from '../../components/CardsSection/CardsSection';
import FooterSection from '../../components/FooterSection/Footer';
import AppSteps from '../../components/AppSteps/AppSteps';
import EmojiSection from '../../components/EmojiSection/Emoji';

const HomePage = () => {
  const pageData = useSelector((state) => state.schema.schema);
  console.log(pageData);

  return (
    <div>
      <div className='main'>
        <Header data={pageData.header} />
        <Hero data={pageData.hero} />
        <FooterSection data={pageData.footer} />
      </div>
    </div>
  );
};

export default HomePage;
