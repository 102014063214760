import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Header from '../../components/HeaderSection/Header';
import FooterSection from '../../components/FooterSection/Footer';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import './BetaThanks.css';

const BetaThanks = () => {
  const pageData = useSelector((state) => state.schema.schema);
  const [copyText, setCopyText] = useState('');
  const location = useLocation();
  const os = location.state.os;

  const handleCopy = () => {
    setCopyText(pageData.betaThanks.textContainerShare.shareLink);
    navigator.clipboard.writeText(pageData.betaThanks.textContainerShare.shareLink);
  }

  return (
    <div>
        <div className='main'>
        <Header data={pageData.header} />
        <section
          id='home'
          className='section welcome-area overflow-hidden d-flex align-items-center'
        >
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-12 col-md-7 col-lg-6'>
                <div className='welcome-intro welcome-intro-responsive'>
                  <div className='beta_title_container'>
                    <p className='beta_title'>{pageData.betaThanks.title} <span>{pageData.betaThanks.highlightTitle}</span></p>
                  </div>
                  <div className='beta_subtitle_container'> 
                    <p className='beta_subtitle2 beta_gray'>{pageData.betaThanks.text1} <span>{pageData.betaThanks.highlightText1}</span></p>
                    <p className='beta_subtitle2 beta_gray'>{pageData.betaThanks.text2}</p>
                  </div>
                  <div className='beta_container_download'>
                    <OutlinedInput
                      defaultValue={os === 'Android' ? pageData.betaThanks.androidLink : pageData.betaThanks.iosLink}
                      readOnly={true}
                      sx={{borderRadius: 50, backgroundColor: '#554863', color: '#C4C4C4', width: '70%', paddingX: 2, fontSize: 15, fontWeight: 100, marginRight: 1}}
                    />

                    <IconButton 
                      sx={{backgroundColor: '#5DCEC3', color: '#FFFFFF', padding: '.5em'}}
                      href={os === 'Android' ? pageData.betaThanks.androidLink : pageData.betaThanks.iosLink}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <FileDownloadOutlinedIcon />
                    </IconButton>
                  </div>
                  <p className='beta_subtitle beta_white'>{pageData.betaThanks.textContainerShare.title}</p>
                  <p className='beta_gray my-4 pHero'>{pageData.betaThanks.textContainerShare.text}</p>
                  <div className='beta_container_copy'>
                    <OutlinedInput
                      defaultValue={pageData.betaThanks.textContainerShare.shareLink}
                      readOnly={true}
                      sx={{borderRadius: 50, backgroundColor: '#554863', color: '#C4C4C4', width: '70%', paddingLeft: 2, fontSize: 15, fontWeight: 100, marginRight: 1}}
                    />

                    <IconButton 
                      sx={{backgroundColor: '#5DCEC3', color: '#FFFFFF', padding: '.5em'}}
                      onClick={handleCopy}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </div>
                  {copyText && <p style={{color: '#5DCEC3', fontWeight: 'bold', fontSize: 12}}>Link copied</p>}
                </div>
              </div>
              <div className='col-12 col-md-5 col-lg-6'>
                <img className='img-beta' src={pageData.betaThanks.mainFrame} alt='' />
              </div>
            </div>
          </div>
        </section>
        <FooterSection data={pageData.footer} />
        </div>
    </div>
  )
}

export default BetaThanks;