import React from 'react';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { decode } from 'html-entities';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';

import './MailchimpForm.css';

const MailchimForm = ({ status, message, onValidated, pageData }) => {
  const history = useHistory();
  const [errors, setErrors] = useState({
    email: '',
    city: '',
    os: '',
  });

  const [betaData, setBetaData] = useState({
    email: '',
    city: '',
    os: '',
  });

  const handleFormSubmit = () => {
    setErrors({
      email: '',
      city: '',
      os: '',
    });

    if (!betaData.email) {
      setErrors({
        email: 'Please enter a valid email address',
      });
      return null;
    }

    if (!betaData.city) {
      setErrors({
        city: 'Please enter a valid city',
      });
      return null;
    }

    if (!betaData.os) {
      setErrors({
        os: 'Please choose an OS',
      });
      return null;
    }

    const isFormValidated = onValidated({
      EMAIL: betaData.email,
      CITY: betaData.city,
      OS: betaData.os,
      tags: '7374269', // Toogether Beta code
    });

    return (
      betaData.email &&
      betaData.city &&
      betaData.os &&
      betaData.email.indexOf('@') > -1 &&
      isFormValidated
    );
  };

  const getMessage = (message) => {
    if (!message) return null;

    const result = message?.split('-') ?? null;
    if ('0' !== result?.[0]?.trim()) {
      return decode(message);
    }
    const formattedMessage = result?.[1]?.trim() ?? null;
    return formattedMessage ? decode(formattedMessage) : null;
  };

  const inputsHandleChange = (event) => {
    setBetaData({
      ...betaData,
      [event.target.name]: event.target.value,
    });
  };

  const selectHandleChange = (event) => {
    setBetaData({
      ...betaData,
      os: event.target.value,
    });
  };

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(0),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      backgroundColor: '#FFFFFF',
      fontSize: 16,
      
      padding: '25px 26px 5px 12px',
      '&:focus': {
        backgroundColor: '#FFFFFF',
      },
    },
  }));

  const ColorButton = styled(Button)(() => ({
    color: '#FFFFFF',
    backgroundColor: '#ff5a54',
    fontSize: 20,
    fontWeight: 100,
    marginBottom: 30,
    width: '50%',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#ff5a54',
    },
  }));

  return (
    <div>
      <Box className='form_container_beta'>
        <FormControl fullWidth>
          <TextField
            type='email'
            autoComplete='off'
            name='email'
            label={pageData.inputs.labelEmail}
            size='small'
            variant='filled'
            value={betaData.email}
            onChange={inputsHandleChange}
            sx={{
              borderRadius: 1,
              backgroundColor: '#FFFFFF',
              marginBottom: 2,
            }}
          />
          <TextField
            type='text'
            autoComplete='off'
            name='city'
            label={pageData.inputs.labelCity}
            size='small'
            variant='filled'
            value={betaData.city}
            onChange={inputsHandleChange}
            sx={{
              borderRadius: 1,
              backgroundColor: '#FFFFFF',
              marginBottom: 2,
            }}
          />
        </FormControl>
        <FormControl variant='filled' fullWidth>
          <InputLabel>{pageData.inputs.labelOs}</InputLabel>
          <Select
            value={betaData.os}
            label={pageData.inputs.labelOs}
            onChange={selectHandleChange}
            input={<BootstrapInput />}
          >
            {
              pageData.menuItemOs.map(os => {
                return (
                  <MenuItem key={os.id} value={os.nameOs}>{os.nameOs}</MenuItem>
                )
              })
            }
          </Select>
        </FormControl>
      </Box>
      {status === 'sending' && <p className='message_loading'>...loading</p>}
      {status === 'error' || errors.email || errors.city || errors.os ? (
        <div
          className='error_message_beta'
          dangerouslySetInnerHTML={{
            __html:
              errors.email || errors.city || errors.os || getMessage(message),
          }}
        />
      ) : null}
      {status === 'success' &&
        status !== 'error' &&
        !errors.email &&
        !errors.city &&
        !errors.os &&
        history.push(
          '/beta-thanks',
          { os: betaData.os }
        )
      }
      <div className='form_container_beta'>
        <ColorButton variant='contained' onClick={handleFormSubmit}>
          {pageData.button.text}
        </ColorButton>
      </div>
    </div>
  );
};

export default MailchimForm;
