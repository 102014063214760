import React from 'react';

const FooterList = (props) => {
  return (
    <div className='col-12 col-sm-6 col-lg-3'>
      {/* Footer Items */}
      <div className='footer-items'>
        {/* Footer Title */}
        <h3 className='footer-title mb-2'>{props.title}</h3>
        <ul>
          {props.list &&
            props.list.map((item, idx) => {
              return (
                <li key={`flo_${idx}`} className='py-2'>
                  <a href={item.link}>{item.text}</a>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default FooterList;
