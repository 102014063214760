import { GET_SCHEMA_REQUEST, GET_SCHEMA_SUCCESS } from '../constants/actions';

import { SCHEMA } from '../data/data';

export const schemaReducer = (state = { schema: { ...SCHEMA } }, action) => {
  switch (action.type) {
    case GET_SCHEMA_REQUEST:
      return {
        loading: true,
      };
    case GET_SCHEMA_SUCCESS:
      return {
        schema: { ...SCHEMA }, // using harcoded data
      };
    case 'fail':
      return {
        loading: false,
      };

    default:
      return state;
  }
};
