import React from 'react';
import Navbar from './Navbar';
import './Header.css';

const Header = ({ data }) => {
  return (
    <header className='navbar navbar-sticky navbar-expand-lg navbar-dark'>
      <div className='container position-relative'>
        <a className='navbar-brand' href='/'>
          <img
            className='navbar-brand-regular logo'
            src={data.logo}
            alt='brand-logo'
          />
        </a>

        {/*  icono de tres lineas toogle */}
        <button
          className='navbar-toggler d-lg-none'
          type='button'
          data-toggle='navbarToggler'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon' />
        </button>

        <div className='navbar-inner'>
          {/*  Mobile Menu Toggler */}
          <button
            className='navbar-toggler d-lg-none'
            type='button'
            data-toggle='navbarToggler'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon' />
          </button>
          <Navbar data={data.navbar} />
        </div>
      </div>
    </header>
  );
};

export default Header;