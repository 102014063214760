import React, { useState } from 'react';
import './Button.css';

const Button = (props) => {

    return (
        <a
        className={props.class} 
        href={props.href} 
        onClick={props.onClick}
        rel="noopener noreferrer"
        target="_blank"
        >
            {props.buttonText}
        </a>
    );
};

export default Button;
