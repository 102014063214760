export const SCHEMA = {
  header: {
    logo: './img/Branding/toogether-5.png',
    navbar: {
      links: [
        {
          id: 1,
          title: 'Home',
          url: '/',
        },
        {
          id: 2,
          title: 'Feedback form',
          url: 'https://forms.gle/JR17keA8ZUUJ8dde7',
        },
      ],
      socialButtons: [
        {
          id: 0,
          link: 'tiktok',
          iconClass: 'fab fa-github',
          href: 'https://github.com/toogether-app',
        },
        {
          id: 2,
          link: 'linkedin',
          iconClass: 'fab fa-linkedin',
          href: 'https://www.linkedin.com/company/toogether-app/about/?viewAsMember=true',
        },
        {
          id: 3,
          link: 'instagram',
          iconClass: 'fab fa-instagram',
          href: 'https://www.instagram.com/toogether.app/',
        },
      ],
      button: {
        text: 'Toogether Beta',
        url: '/beta',
      },
    },
  },
  hero: {
    logo: './img/Branding/toogether-6.png',
    text: 'We are here to make your university life incredible. Meet other students, make plans, have awesome parties and fun like never before. With toogether you can take your Uni experience to a whole new level',
    frame: './img/Frames/landing.png',
    appStoreButtons: [
      {
        name: 'android',
        imagen: './img/AppButtons/google-play-black.png',
        url: '/beta',
        alt: 'Download Toogether for Android',
      },
      {
        name: 'apple',
        imagen: './img/AppButtons/app-store-black.png',
        url: '/beta',
        alt: 'Download Toogether for IOS',
      },
    ],
  },
  featureCardsSection: {
    title: "What's the deal with toogether?",
    subtitle: 'Is not just a cool logo',
    cards: [
      {
        id: 'new-people',
        icon: './img/Icons/icon-1.png',
        title: 'Connect with students',
        subtitle:
          'Connect and organize with other groups of students who are also looking to party',
      },
      {
        id: 'places',
        icon: './img/Icons/icon-6.png',
        title: 'All the Bristol Unis',
        subtitle:
          'Come to our events, meet other students from different Unis, expand your circle and enjoy the night',
      },
      {
        id: '',
        icon: './img/Icons/icon-2.png',
        title: 'Best app to party',
        subtitle:
          'Go with your friends to new parties and cool events every week. We keep it fresh and epic',
      },
    ],
  },
  stepsSection: {
    title: 'Making Uni life epic',
    subtitle: 'So..... how?',

    steps: [
      {
        id: 'theGroup',
        icon: './img/Icons/icon-10.png',
        title: 'The Group',
        subtitle:
          'The night starts with your friends. Go in the app, create a group and navigate through the swipe section. Discover groups of students that, like you, are looking for something fun to do in the night.',
      },
      {
        id: 'theMatch',
        icon: './img/Icons/icon-8.png',
        title: 'The Match',
        subtitle:
          'When you get the group match, you will be able to talk through the chat. Get to know each other, share stories and find the things you have in common, surely there must be plenty.',
      },
      {
        id: 'theMeet',
        icon: '/img/Icons/icon-9.png',
        title: 'The Meet',
        subtitle:
          'If you find a cool group and you are ready to hang, agree to meet at a bar or club that is convenient for everyone. The night is about to start, enjoy!',
      },
    ],

    frames: [
      {
        id: '1',
        image: './img/Frames/feature-1.png',
      },
      {
        id: '2',
        image: './img/Frames/feature-2.png',
      },
      {
        id: '3',
        image: './img/Frames/feature-3.png',
      },
      {
        id: '4',
        image: './img/Frames/feature-4.png',
      },
      {
        id: '5',
        image: './img/Frames/feature-5.png',
      },
    ],
  },
  footer: {
    logo: './img/Branding/toogether-5.png',
    text: 'Making university life more fun. Get in the App, meet other students and party',
    title_1: 'About us',
    title_2: 'People',
    title_3: 'Download',
    socialButtons: [
      {
        id: 0,
        link: 'tiktok',
        iconClass: 'fab fa-github',
        href: 'https://github.com/toogether-app',
      },
      {
        id: 2,
        link: 'linkedin',
        iconClass: 'fab fa-linkedin',
        href: 'https://www.linkedin.com/company/toogether-app/about/?viewAsMember=true',
      },
      {
        id: 3,
        link: 'instagram',
        iconClass: 'fab fa-instagram',
        href: 'https://www.instagram.com/toogether.app/',
      },
    ],
    footerList_1: [
      {
        id: 1,
        link: '/',
        text: 'Home',
      },
      {
        id: 2,
        link: '/terms-and-conditions',
        text: 'Terms and Conditions',
      },
      {
        id: 3,
        link: '/privacy-policy',
        text: 'Privacy Policy',
      },
      {
        id: 4,
        link: '/community-guidelines',
        text: 'Community Guidelines',
      },
      {
        id: 5,
        link: '/safety-tips',
        text: 'Safety Tips',
      },
    ],
    footerList_2: [
      {
        id: 1,
        link: 'mailto:damianstonedev@gmail.com',
        text: 'Work with us (coding)',
      },
      {
        id: 2,
        link: 'mailto:damianstonedev@gmail.com',
        text: 'Internships',
      },
      {
        id: 3,
        link: 'mailto:francisco.vivesstone@gmail.com',
        text: 'Contact us',
      },
    ],
    footerList_3: [
      {
        id: 1,
        image: '/img/AppButtons/google-play-black.png',
        href: '/beta',
      },
      {
        id: 2,
        image: '/img/AppButtons/app-store-black.png',
        href: '/beta',
      },
    ],
  },
  beta: {
    title: 'Toogether',
    highlightTitle: 'Beta',
    subTitle: '100 invitations available for IOS and Android',
    inputs: {
      labelEmail: 'Email',
      labelCity: 'City',
      labelOs: 'Operating system',
    },
    menuItemOs: [
      {
        id: 0,
        nameOs: 'Android',
      },
      {
        id: 1,
        nameOs: 'iOS',
      }
    ],
    button: {
      text: 'Get the app',
    },
    textContainers: {
      title: 'Whats Toogether Beta?',
      text: `Toogether Beta is an exciting opportunity to try out our upcoming app before it hits the market. By joining our beta testing program on TestFlight, you'll get an exclusive sneak peek at the app's features and have the chance to provide valuable feedback to our development team and`,
      highlightText: 'help shape the future of the app.',
    },
    mainFrame: './img/Frames/toogether-beta.png',
  },
  betaThanks: {
    title: 'Toogether',
    highlightTitle: 'Beta',
    text1: 'Thanks for joining',
    highlightText1: 'Toogether ;)',
    text2: 'Here is the link to download the app 🚀',
    androidLink: 'https://play.google.com/store/apps/details?id=toogether.app',
    iosLink: 'https://testflight.apple.com/join/YjrDODGS',
    textContainerShare: {
      title: 'Together works better with friends',
      text: 'Share this so your friends can also start using the app',
      shareLink: 'https://toogether.app/beta',
    },
    mainFrame: './img/Frames/toogether-beta.png',
  },
};
