import React from 'react';

const FooterBottom = () => {
  return (
    <>
      <div className='footer-bottom'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              {/* Copyright Area */}
              <div className='copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4'>
                {/* Copyright Left */}
                <div className='copyright-left'>
                  © Copyrights 2021 toogether App All rights reserved.
                </div>
                {/* Copyright Right */}
                <div className='copyright-right'>
                  Made with <i className='fas fa-heart' /> By{' '}
                  <a href='/#'>toogether</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterBottom;
