import React, { useEffect } from "react";
import { initGA, trackingPageGA } from "./reactGA";
import MyRouts from "./routers/routes";

const App = () => {
  useEffect(() => {
    initGA();
    trackingPageGA("/home");
  }, []);

  return (
    <div>
      <MyRouts />
    </div>
  );
};

export default App;
