import React from 'react';
import Button from '../Button/Button';
import './Navbar.css'

const Navbar = ({ data }) => {
  return (
    <>
      <ul className='navbar-nav mr-auto' id='navbar-nav'>
        {data.links &&
          data.links.map((item) => (
            <li className='nav-item' key={item.id}>
              <a className='nav-link links' href={item.url}>
                {item.title}
              </a>
            </li>
          ))}
        <div className='social-icons d-flex-social nav-item'>
          {data.socialButtons &&
            data.socialButtons.map((item, idx) => {
              return (
                <a
                  key={`fi_${idx}`}
                  className={item.link}
                  href={item.href}
                  target='m_blank'
                >
                  <i className={item.iconClass} />
                  <i className={item.iconClass} />
                </a>
              );
            })}
        </div>
        <div className='button-navbar d-flex-social nav-item'>
          <a href={data.button.url}>
            <Button
              buttonText={data.button.text}
              class='Button'
            />
          </a>
        </div>
      </ul>
    </>
  );
};

export default Navbar;
