import React from 'react';
import FooterBottom from './FooterBottom';
import FooterList from './FooterList';

const FooterSection = ({ data }) => {
  return (
    <section>
      <div className='height-emulator d-none d-lg-block' />
      <footer className='footer-area footer-fixed'>
        {/* Footer Top */}
        <div className='footer-top ptb_100'>
          <div className='container'>
            <div className='row'>
              <div className='col-12 col-sm-6 col-lg-3'>
                <div className='footer-items'>
                  <a className='navbar-brand' href='/#'>
                    <img className='footer-logo' src={data.logo} alt='' />
                  </a>
                  <p className='mt-2 mb-3'>{data.text}</p>
                  <div className='social-icons d-flex'>
                    {data.socialButtons &&
                      data.socialButtons.map((item, idx) => {
                        return (
                          <a
                            key={`fi_${idx}`}
                            className={item.link}
                            href={item.href}
                            target='m_blank'
                          >
                            <i className={item.iconClass} />
                            <i className={item.iconClass} />
                          </a>
                        );
                      })}
                  </div>
                </div>
              </div>
              <FooterList title={data.title_1} list={data.footerList_1} />
              <FooterList title={data.title_2} list={data.footerList_2} />
              <div className='col-12 col-sm-6 col-lg-3'>
                <div className='footer-items'>
                  <h3 className='footer-title mb-2'>{data.title_3}</h3>
                  <div className='button-group store-buttons store-black d-flex flex-wrap'>
                    {data.footerList_3 &&
                      data.footerList_3.map((item, idx) => {
                        return (
                          <a key={`flth_${idx}`} href={item.href}>
                            <img src={item.image} alt='' />
                          </a>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterBottom />
      </footer>
    </section>
  );
};

export default FooterSection;
