import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import HomePage from '../pages/home/HomePage';
import ErrorPage from '../components/ErrorPage/404';
import TermPage from '../pages/term/TermPage';
import Beta from '../pages/Beta/Beta';
import BetaThanks from '../pages/BetaThanks/BetaThanks';

class MyRouts extends React.Component {
  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route exact path='/' component={HomePage} />
            <Route path='/beta' component={Beta} />
            <Route path='/beta-thanks' component={BetaThanks} />
            <Route path='/terms-and-conditions' component={TermPage} />
            <Route path='/privacy-policy' component={TermPage} />
            <Route path='/community-guidelines' component={TermPage} />
            <Route path='/safety-tips' component={TermPage} />
            <Route path='/error-page' component={ErrorPage} />
          </Switch>
        </Router>
      </div>
    );
  }
}
export default MyRouts;
