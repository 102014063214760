import React from 'react';
import { eventGA } from '../../reactGA';
import './Hero.css';

const Hero = ({ data }) => {
  const counterAppleButton = () => {
    eventGA('downloadButton', 'IOS');
  };

  const counterAndroidButton = () => {
    eventGA('downloadButton', 'android');
  };

  const checkButtonType = (name) => {
    if (name === 'android') {
      counterAndroidButton();
    }
    if (name === ' apple') {
      counterAppleButton();
    }
  };

  return (
    <section
      id='home'
      className='section welcome-area overflow-hidden d-flex align-items-center'
    >
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-7 col-lg-6'>
            <div className='welcome-intro welcome-intro-responsive'>
              <img
                src={data.logo}
                className='logoH'
                alt='logo toogether an app to find nice parties ;)'
              />
              <p className='text-white my-4 pHero'>{data.text}</p>

              {/* Store Buttons */}
              <div className='button-group store-buttons d-flex'>
                {data.appStoreButtons &&
                  data.appStoreButtons.map((button) => (
                    <a
                      onClick={() => checkButtonType(button.name)}
                      href={button.url}
                    >
                      <img src={button.imagen} alt={button.alt} />
                    </a>
                  ))}
              </div>
            </div>
          </div>
          <div className='col-12 col-md-5 col-lg-6'>
            <img className='img-hero' src={data.frame} alt='' />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
