import React from 'react';
import { useSelector } from 'react-redux';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import Header from '../../components/HeaderSection/Header';
import MailchimForm from '../../components/MailchimpForm/MailchimpForm';
import FooterSection from '../../components/FooterSection/Footer';
import './Beta.css';

const Beta = () => {
  const MAILCHIMP_URL = process.env.REACT_APP_MAILCHIMP_URL;
  const pageData = useSelector((state) => state.schema.schema);

  return (
    <div>
      <div className='main'>
        <Header data={pageData.header} />
        <section
          id='home'
          className='section welcome-area overflow-hidden d-flex align-items-center'
        >
          <div className='container'>
            <div className='row align-items-center betaContainer'>
              <div className='col-12 col-md-7 col-lg-6'>
                <div className='welcome-intro welcome-intro-responsive'>
                  <div className='beta_title_container'>
                    <p className='beta_titles'>{pageData.beta.title} <span>{pageData.beta.highlightTitle}</span></p>
                    <p className='beta_subtitles beta_gray'>{pageData.beta.subTitle}</p>
                  </div>
                  <MailchimpSubscribe
                    url={MAILCHIMP_URL}
                    render={( props) => {
                      const { subscribe, status, message } = props || {};
                      return (
                        <MailchimForm
                          status={status}
                          message={message}
                          onValidated={(formData) => subscribe(formData)}
                          pageData={pageData.beta}
                        />
                      );
                    }}
                  />
                  <p className='beta_subtitles beta_white'>{pageData.beta.textContainers.title}</p>
                  <p className='text-white my-2 pBeta'>{pageData.beta.textContainers.text} <span>{pageData.beta.textContainers.highlightText}</span></p>
                </div>
              </div>
              <div className='col-12 col-md-5 col-lg-6'>
                <img className='img-beta' src={pageData.beta.mainFrame} alt='' />
              </div>
            </div>
          </div>
        </section>
        <FooterSection data={pageData.footer} />
      </div>
    </div>
  )
}

export default Beta;